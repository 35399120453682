var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"match-height"},[_c('v-col',{attrs:{"cols":"12"}},[_c('app-card-code',{attrs:{"title":((_vm.$t('menu.products')) + " / " + (_vm.$t('menu.flights')))}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"error","loading":_vm.loading},on:{"click":function($event){return _vm.$router.push({ name: 'flights-list' })}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiArrowLeft))]),_c('span',[_vm._v(_vm._s(_vm.$t('btn.back')))])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.save()}}},[_c('span',[_vm._v(_vm._s(_vm.btnTitle))]),_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiUpdate))])],1)],1)],1),_c('v-form',{staticClass:"multi-col-validation"},[_c('v-row',{staticClass:"pb-5"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsAerolinea,"search-input":_vm.searchAerolinea,"hide-details":"","hide-selected":"","label":_vm.$t('lbl.operador'),"outlined":"","dense":"","item-text":"name","item-value":"id"},on:{"update:searchInput":function($event){_vm.searchAerolinea=$event},"update:search-input":function($event){_vm.searchAerolinea=$event},"change":_vm.changeAerolinea},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.operador'))+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.name)}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]}}]),model:{value:(_vm.model.aerolinea_id),callback:function ($$v) {_vm.$set(_vm.model, "aerolinea_id", $$v)},expression:"model.aerolinea_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{ref:"numero_de_vuelo",attrs:{"prefix":_vm.iataAeroline,"label":_vm.$t('lbl.numeroVuelo'),"outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.model.numero_de_vuelo),callback:function ($$v) {_vm.$set(_vm.model, "numero_de_vuelo", $$v)},expression:"model.numero_de_vuelo"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.aeronaves,"item-text":"modelo","item-value":"id","label":_vm.$t('lbl.aeronave'),"outlined":"","dense":"","hide-details":""},model:{value:(_vm.model.aeronave_id),callback:function ($$v) {_vm.$set(_vm.model, "aeronave_id", $$v)},expression:"model.aeronave_id"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pb-2 pt-3",attrs:{"cols":"12","sm":"2","md":"2"}},[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.frecuencias')))])]),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.adicionarFrecuencia()}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])])],1),(!_vm.$store.state.app.loadFrecuencia)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.frecuencias.length > 0)?_c('v-divider'):_vm._e(),_vm._l((_vm.frecuencias),function(frecuencia,i){return _c('Frecuencias',{key:i,staticClass:"pt-2",attrs:{"pos":i,"frecuencia":frecuencia}})})],2):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }