var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{class:_vm.pos > 0 ? 'mt-6' : '',attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsCity,"search-input":_vm.searchCity,"hide-details":"","hide-selected":"","label":_vm._f("capitalize")(_vm.$t('lbl.escala')),"outlined":"","dense":"","item-text":"name","item-value":"id","return-object":"","disabled":_vm.posFrecuencia > 0},on:{"update:searchInput":function($event){_vm.searchCity=$event},"update:search-input":function($event){_vm.searchCity=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.citySearch'))+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name !== undefined ? ((item.name.slice(0, 12)) + " ...") : ''))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiAirplaneTakeoff)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]}}]),model:{value:(_vm.escala.city),callback:function ($$v) {_vm.$set(_vm.escala, "city", $$v)},expression:"escala.city"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"ml-2",attrs:{"cols":"12","md":"6"}},[_c('vc-date-picker',{attrs:{"outlined":"","dense":"","mode":"time","model-config":_vm.modelTimeConfig,"is24hr":""},on:{"input":_vm.calculateDurationEscala},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('lbl.llegada'),"outlined":"","dense":"","value":inputValue}},inputEvents))]}}]),model:{value:(_vm.escala.llegada),callback:function ($$v) {_vm.$set(_vm.escala, "llegada", $$v)},expression:"escala.llegada"}})],1),_c('v-col',{staticClass:"pt-2 pl-1",attrs:{"cols":"12","md":"1"}},[_vm._v(" + ")]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.day'),"outlined":"","dense":"","hide-details":"","type":"number","min":"0"},on:{"input":function($event){return _vm.changeDaysCalculate(_vm.posFrecuencia)}},model:{value:(_vm.escala.llegadaDay),callback:function ($$v) {_vm.$set(_vm.escala, "llegadaDay", $$v)},expression:"escala.llegadaDay"}})],1)],1)],1),_c('v-col',{staticClass:"ml-2",attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.terminal'),"outlined":"","dense":"","hide-details":""},model:{value:(_vm.escala.llegadaTerminal),callback:function ($$v) {_vm.$set(_vm.escala, "llegadaTerminal", $$v)},expression:"escala.llegadaTerminal"}})],1),_c('v-col',{staticClass:"ml-3",attrs:{"cols":"12","md":"5"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.duration'),"outlined":"","dense":"","hide-details":"","disabled":""},model:{value:(_vm.escala.llegadaDuration),callback:function ($$v) {_vm.$set(_vm.escala, "llegadaDuration", $$v)},expression:"escala.llegadaDuration"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-tooltip',{attrs:{"slot":"append-outer","top":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('msg.durationHourMin')))])])],1)],1)],1)],1)],1),_c('v-col',{staticClass:"ml-5",attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('vc-date-picker',{attrs:{"outlined":"","dense":"","mode":"time","model-config":_vm.modelTimeConfig,"is24hr":""},on:{"input":_vm.calculateDurationEscala},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({ref:"dateTo",attrs:{"label":_vm.$t('lbl.departure'),"outlined":"","dense":"","value":inputValue}},inputEvents))]}}]),model:{value:(_vm.escala.salida),callback:function ($$v) {_vm.$set(_vm.escala, "salida", $$v)},expression:"escala.salida"}})],1),_c('v-col',{staticClass:"pt-2 pl-1",attrs:{"cols":"12","md":"1"}},[_vm._v(" + ")]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.day'),"outlined":"","dense":"","hide-details":"","type":"number","min":"0"},on:{"input":function($event){return _vm.changeDaysCalculate(_vm.posFrecuencia)}},model:{value:(_vm.escala.salidaDay),callback:function ($$v) {_vm.$set(_vm.escala, "salidaDay", $$v)},expression:"escala.salidaDay"}})],1)],1)],1),_c('v-col',{staticClass:"ml-2",attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.terminal'),"outlined":"","dense":"","hide-details":""},model:{value:(_vm.escala.salidaTerminal),callback:function ($$v) {_vm.$set(_vm.escala, "salidaTerminal", $$v)},expression:"escala.salidaTerminal"}})],1),_c('v-col',{staticClass:"ml-3",attrs:{"cols":"12","md":"5"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.duration'),"outlined":"","dense":"","hide-details":"","disabled":""},model:{value:(_vm.escala.salidaDuration),callback:function ($$v) {_vm.$set(_vm.escala, "salidaDuration", $$v)},expression:"escala.salidaDuration"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-tooltip',{attrs:{"slot":"append-outer","top":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('msg.durationEscHourMin')))])])],1)],1)],1)],1)],1),(_vm.posFrecuencia === 0)?_c('v-col',{staticClass:"ml-3 text-right",attrs:{"cols":"12","sm":"1","md":"1"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[(_vm.pos === 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.addEscalaFrecuencia(_vm.posFrecuencia)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,2560271026)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])]):_vm._e()],1),(_vm.frecuencias[_vm.posFrecuencia].escalas.length > 1)?_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteEscalaFrecuencia({ pos: _vm.pos, posFrecuencia: _vm.posFrecuencia })}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}],null,false,2539357547)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])])],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }